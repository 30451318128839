<template>
  <div class="sld_invoice">
      <MemberTitle memberTitle="我的发票"></MemberTitle>
      <div class="add_default_con" v-if="!invoiceFlag">
        <div class="flex_row_start_start" style="flex-wrap: wrap;">
          <div class="default_con_box default_con_box_11">
            <div class="default_con_box_h4">发票内容</div>
            <div class="default_con_box_p flex_row_start_center">
              <span>单位名称：</span>
              <span>{{enterpriseName?enterpriseName:'--'}}</span>
            </div>
            <div class="default_con_box_p flex_row_start_center">
              <span>纳税人识别号：</span>
              <span>{{taxCode?taxCode:'--'}}</span>
            </div>
            <div class="default_con_box_p flex_row_start_center">
              <span>注册地址：</span>
              <span>{{Invoices.data.registerAddr?Invoices.data.registerAddr:'--'}}</span>
            </div>
            <div class="default_con_box_p flex_row_start_center">
              <span>注册电话：</span>
              <span>{{Invoices.data.registerPhone?Invoices.data.registerPhone:'--'}}</span>
            </div>
            <div class="default_con_box_p flex_row_start_center">
              <span>开户银行：</span>
              <span>{{Invoices.data.bankName?Invoices.data.bankName:'--'}}</span>
            </div>
            <div class="default_con_box_p flex_row_start_center">
              <span>银行账户：</span>
              <span>{{Invoices.data.bankAccount?Invoices.data.bankAccount:'--'}}</span>
            </div>
          </div>
          <div class="default_con_box" style="margin-left: 47px;">
            <div class="default_con_box_h4">发票地址</div>
            <div class="default_con_box_p flex_row_start_center" v-if="default_address.data.length==0">
              <span>暂无默认地址</span>
            </div>
            <div class="default_con_box_p flex_row_start_center" v-if="default_address.data.length>0">
              <span>{{default_address.data[0].receiverName}}</span>
              <span style="margin-left: 42px;">{{default_address.data[0].receiverMobile}}</span>
            </div>
            <div class="default_con_box_p flex_row_start_center" v-if="default_address.data.length>0">
              <span>{{default_address.data[0].addressAll}}{{default_address.data[0].receiverAddress}}</span>
            </div>
            <div class="default_con_box_btn flex_row_center_center" @click="goaddlist">
              管理发票地址
            </div>
          </div>
        </div>
      </div>
      <div class="address_list_con" v-else>
        <div class="container">
          <h3>管理发票地址</h3>
          <div class="add_container_btn flex_row_center_center" @click="addAddress">
            新增地址
          </div>
          <div class="container_list flex_row_start_center">
            <p class="list_name">收票人姓名</p>
            <p class="list_phone">手机号码</p>
            <p class="list_phone">电子邮箱</p>
            <p class="list_address">地址</p>
            <p class="operation">操作</p>
          </div>
          <div class="container_tbody flex_row_start_center" v-for="(item,index) in address_list.data" :key="index">
            <p class="list_name">{{item.receiverName}}</p>
            <p class="list_phone">{{item.receiverMobile}}</p>
            <p class="list_phone">{{item.receiverMailbox}}</p>
            <p class="list_address">{{item.addressAll}}{{item.receiverAddress}}</p>
            <div class="operation flex_row_between_center">
              <div class="operation_address" v-if="item.isDefault==1">默认地址</div>
              <div v-else class="operation_address_o" @click="setDefault(item.receiveInvoiceAddressId)">设为默认</div>
              <div @click="editAddress(item)">编辑</div>
              <div @click="delAddress(item.receiveInvoiceAddressId)">删除</div>
            </div>
          </div>
        </div>
      </div>
  </div>
  <SldAddressInvoice v-if="addressVisibleFlag" @close='close' @refreshAddress='refreshAddress' :visibleFlag="true"
        :type='type' :addressInfo='addressInfo' />
</template>
<script>
  import { ElMessage, ElMessageBox } from "element-plus";
  import { ref, getCurrentInstance, reactive, onMounted } from "vue";
  import SldAddressInvoice from "../../components/AddressInvoice";
  import SldCommonEmpty from '../../components/SldCommonEmpty';
  import MemberTitle from '../../components/MemberTitle'
  export default {
      name: "MemberInvoice",
      components: {
          SldAddressInvoice,
          SldCommonEmpty,
          MemberTitle
      },
      setup() {
          const addressVisibleFlag = ref(false);
          const { proxy } = getCurrentInstance();
          const L = proxy.$getCurLanguage();
          const addressbg = require("../../assets/addressbg.png");
          const addressbg2 = require("../../assets/addressbg2.png");
          const address_list = reactive({ data: [] });
          const default_address = reactive({ data: [] });
          const addressInfo = reactive({ data: {} })
          const invoiceFlag = ref(false);
          const type = ref('add')
          const enterpriseName =ref('');//单位名称
          const taxCode =ref('');//纳税人识别号
          //点击添加地址事件
          const addAddress = () => {
            console.log(111);
              type.value = 'add'
              addressVisibleFlag.value = true;
          };
          //编辑地址事件
          const editAddress = (address) => {
              console.log(address)
              addressInfo.data = address
              type.value = 'edit'
              addressVisibleFlag.value = true;
          }
          //关闭弹窗
          const close = () => {
              addressVisibleFlag.value = false
          }
          //获取地址列表
          const getAddressList = () => {
              proxy
                  .$get("v3/member/front/receiveInvoiceAddress/list", {
                      pageSize: 1000
                  })
                  .then(res => {
                      if (res.state == 200) {
                          address_list.data = res.data;
                          default_address.data = res.data.filter(item=> {
                              return item.isDefault == 1;
                          });
                          console.log(default_address.data);
                      } else {
                          ElMessage(res.msg);
                      }
                  })
                  .catch(() => {
                      //异常处理
                  });
          };
          //删除地址
          const delAddress = (addressId) => {

              ElMessageBox.confirm('确定删除该地址?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
              }).then(() => {
                  proxy.$post("v3/member/front/receiveInvoiceAddress/del", {
                    receiveInvoiceAddressId: addressId
                  }).then(res => {
                      if (res.state == 200) {
                          ElMessage.success(res.msg)
                          getAddressList()
                      } else {
                          ElMessage(res.msg);
                      }
                  })
              })
          }
          //设为默认
          const setDefault = (addressId) => {
              proxy
                  .$post("v3/member/front/receiveInvoiceAddress/changeDefaultAddress", {
                      receiveInvoiceAddressId: addressId,
                      isDefault: 1
                  })
                  .then(res => {
                      if (res.state == 200) {
                          ElMessage.success(res.msg)
                          getAddressList()
                      } else {
                          ElMessage(res.msg);
                      }
                  })
                  .catch(() => {
                      //异常处理
                  });
          }

          const add_invo = ()=>{

          }
          const refreshAddress = () => {
              getAddressList();
          }

          const goaddlist = ()=>{
            console.log('====================================');
            console.log(applyDetail.data.enterpriseAuditState);
            console.log('====================================');
            if(applyDetail.data.enterpriseAuditState!=2){
              ElMessage('请先提交经营信息');
              return
            }
            invoiceFlag.value = true
          }
          const Invoices = reactive({data:{}});
          const applyDetail = reactive({data:{}});
          onMounted(() => {
            proxy.$post('/v3/member/front/memberEnterPrise/apply/detail').then(ret => {
              applyDetail.data = ret.data
              if(ret.data.memberInvoiceVO){
                taxCode.value = ret.data.memberInvoiceVO.taxCode
                enterpriseName.value = ret.data.enterpriseName
                Invoices.data = ret.data.memberInvoiceVO
              }
            })
              getAddressList();
          });
          return {
              addressVisibleFlag,
              addAddress,
              addressbg,
              addressbg2,
              L,
              address_list,
              default_address,
              delAddress,
              refreshAddress,
              type,
              addressInfo,
              editAddress,
              close,
              setDefault,
              invoiceFlag,
              add_invo,
              enterpriseName,
              taxCode,
              goaddlist,
              Invoices,
              applyDetail
          };
      }
  };
</script>
<style lang="scss">
  @import "../../style/invoice.scss";
  @import '../../style/base.scss';

  .el-button--primary {
      color: #FFF;
      background-color: $colorMain;
      border-color: $colorMain;
  }
</style>
